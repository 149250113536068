import uniqBy from 'lodash.uniqby';
import { TASK_DEFAULT_LIMIT } from '../../../constants/trello';

const initialDetail = {
	title: '',
	description: '',
	dueDate: '',
	Project: {},
	Client: {},
	assignedBy: {},
	assignees: [],
	Attachments: [],
	Tags: [],
	createdAt: new Date(),
	priority: '',
};

const initialState = {
	tasks: [],
	loading: false,
	modalLoading: false,
	detailLoading: false,
	detail: initialDetail,
	page: 1,
	canLoadMore: true,
};

const trelloReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'SET_TRELLO_LOADING':
			return {
				...state,
				loading: action.payload,
			};
		case 'SET_TRELLO_DETAIL_MODAL_LOADING':
			return {
				...state,
				detailLoading: action.payload,
			};
		case 'FETCH_TRELLO_TASKS':
			return {
				...state,
				tasks: action.tasks,
				loading: false,
				canLoadMore: action.tasks.length >= TASK_DEFAULT_LIMIT,
			};
		case 'LOAD_MORE_TASKS':
			return {
				...state,
				tasks: uniqBy([...state.tasks, ...action.tasks], 'id'),
				loading: false,
				canLoadMore: action.tasks.length >= TASK_DEFAULT_LIMIT,
			};
		case 'RESET_TRELLO_DETAIL':
			return {
				...state,
				detail: initialDetail,
			};
		case 'SET_TRELLO_TASKS':
			return {
				...state,
				tasks: action.tasks,
			};
		case 'REORDER_TRELLO_TASKS':
			return {
				...state,
				tasks: action.tasks,
			};
		case 'GET_TRELLO_DETAIL':
			return {
				...state,
				detailLoading: true,
			};
		case 'GET_TRELLO_DETAIL_ERROR':
			return {
				...state,
				detailLoading: false,
			};
		case 'GET_TRELLO_DETAIL_SUCCESS':
			return {
				...state,
				detailLoading: false,
				detail: action.payload,
			};
		case 'SET_TRELLO_DETAIL':
			return {
				...state,
				detail: {
					...state.detail,
					...action.payload,
				},
			};
		case 'UPDATE_ORIGINAL_PROJECT_ID':
			return {
				...state,
				detail: {
					...state.detail,
					originalProjectId: action.payload,
				},
			};

		case 'INCREASE_PAGE_COUNT':
			return {
				...state,
				page: state.page + 1,
			};
		case 'RESET_PAGE_COUNT':
			return {
				...state,
				page: 1,
			};
		default:
			return state;
	}
};

export default trelloReducer;
