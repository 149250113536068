const initialState = {
	show: false,
	options: { onConfirm: () => null, onClose: () => null },
};

export default function alertReducer(state = initialState, action) {
	switch (action.type) {
		case 'SHOW_ALERT':
			return {
				...state,
				options: { ...state.options, ...action.options },
				show: true,
			};
		case 'RESET_ALERT':
			return {
				...initialState,
			};
		default:
			return state;
	}
}
