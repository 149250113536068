import React from 'react';
import '../../../assets/scss/logo-loading.scss';

function LogoLoading() {
	return (
		<div className="vh-100">
			<div className="loading-logo">
				<div className="loading-text">
					<span className="loading-text-words">H</span>
					<span className="loading-text-words">Y</span>
					<span className="loading-text-words">P</span>
					<span className="loading-text-words">E</span>
					<span className="loading-text-words">R</span>
					<span className="loading-text-words">A</span>
					<span className="loading-text-words">C</span>
					<span className="loading-text-words">T</span>
					<span className="loading-text-words">I</span>
					<span className="loading-text-words">V</span>
					<span className="loading-text-words">E</span>
				</div>
			</div>
		</div>
	);
}

export default LogoLoading;
