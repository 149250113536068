const initialTask = {
	id: '',
	name: '',
	departmentId: 0,
	billable: false,
	is_default: false,
};

const initialState = {
	tasks: [],
	departments: [],
	loading: false,
	modalLoading: false,
	task: initialTask,
};

const taskReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'SET_LOADING':
			return {
				...state,
				loading: action.payload,
			};
		case 'SET_MODAL_LOADING':
			return {
				...state,
				modalLoading: action.payload,
			};
		case 'INIT_DATA':
			return {
				...state,
				tasks: action.tasks,
				departments: action.departments,
				loading: false,
			};
		case 'SET_TASKS':
			return {
				...state,
				tasks: action.tasks,
			};
		case 'NEW_TASK':
			return {
				...state,
				task: initialTask,
				tasks: [action.task, ...state.tasks],
			};
		case 'RESET_TASK':
			return {
				...state,
				task: initialTask,
			};
		case 'SET_TASK':
			return {
				...state,
				task: action.payload,
			};
		default:
			return state;
	}
};

export default taskReducer;
