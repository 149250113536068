const initialState = {
	services: [],
	users: [],
	projects: [],
	departments: [],
	loading: false,
	modalLoading: false,
	service: {
		id: '',
		project: null,
		department: null,
		limits: null,
		paymentExpiry: null,
		invoiceDay: null,
		firstApprover: null,
		secondApprover: null,
		lastApprover: null,
	},
};
const servicesReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'SET_SERVICE_LOADING':
			return {
				...state,
				loading: action.payload,
			};
		case 'SET_SERVICE_MODAL_LOADING':
			return {
				...state,
				modalLoading: action.payload,
			};
		case 'INIT_SERVICE_DATA':
			return {
				...state,
				users: action.users,
				projects: action.projects,
				departments: action.departments,
				services: action.services,
				loading: false,
			};
		case 'SET_SERVICES':
			return {
				...state,
				services: action.services,
			};
		case 'NEW_SERVICE':
			return {
				...state,
				service: initialState.service,
				services: [action.service, ...state.services],
			};
		case 'SET_SERVICE':
			return {
				...state,
				service: {
					...state.service,
					...action.payload,
				},
			};
		case 'RESET_SERVICE':
			return {
				...state,
				service: initialState.service,
			};
		default:
			return state;
	}
};

export default servicesReducer;
