const initialState = {
	categories: [],
	loading: false,
	modalLoading: false,
	category: { name: '', id: '' },
};
const categoryReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'SET_LOADING':
			return {
				...state,
				loading: action.payload,
			};
		case 'SET_MODAL_LOADING':
			return {
				...state,
				modalLoading: action.payload,
			};
		case 'INIT_DATA':
			return {
				...state,
				categories: action.categories,
				loading: false,
			};
		case 'SET_CATEGORIES':
			return {
				...state,
				categories: action.categories,
			};
		case 'NEW_CATEGORY':
			return {
				...state,
				category: { name: '', id: '' },
				categories: [action.category, ...state.categories],
			};
		case 'SET_CATEGORY':
			return {
				...state,
				category: action.payload,
			};
		case 'SET_CATEGORY_NAME':
			return {
				...state,
				category: { ...state.category, name: action.payload },
			};
		case 'RESET_CATEGORY':
			return {
				...state,
				category: { name: '', id: '' },
			};
		default:
			return state;
	}
};

export default categoryReducer;
