const VAPID_KEY = 'BAAyvqLYzTPesM4BPlesZJf7eGqwyUicTd3oPImsugPlcA961ObVa6V1knGFRM3pfrp273gQ0oSMzvkEyI29eWY';

async function sendSubscription(subscription) {
	const response = await fetch(`${process.env.REACT_APP_BACKEND_API}/api/user/notifications/subscribe`, {
		method: 'POST',
		body: JSON.stringify({
			subscription: subscription,
			title: 'Hype Time',
			description: 'someone buy a product',
			icon: '/bildirim-icon.png',
		}),
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${localStorage.user}`,
		},
	});
	return response.json();
}

async function askPermission() {
	const permission = await Notification.requestPermission();
	if (permission === 'granted') {
		new Notification('Hype Time', {
			body: 'Aramıza Hoş Geldin',
			icon: '/bildirim-icon.png',
			badge: '/bildirim-icon.png',
			vibrate: [200, 100, 200, 100, 200, 100, 200],
			tag: 'time-welcome-notification',
		});
		return true;
	}
	return false;
}

export async function subscribeUser() {
	if (Notification.permission === 'granted') return;
	const isAccepted = await askPermission();
	if (!isAccepted) return;
	try {
		const registration = await navigator.serviceWorker.ready;
		if (!registration.pushManager) return console.error('Push manager unavailable.');
		let subscription = await registration.pushManager.getSubscription();
		if (!subscription) {
			subscription = await registration.pushManager.subscribe({
				applicationServerKey: urlBase64ToUint8Array(VAPID_KEY),
				userVisibleOnly: true,
			});
		}
		return sendSubscription(subscription);
	} catch (error) {
		console.error('An error occurred during Service Worker registration.', error);
	}
}

function urlBase64ToUint8Array(base64String) {
	const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
	const base64 = (base64String + padding)
		.replace(/\-/g, '+') // eslint-disable-line
		.replace(/_/g, '/'); // eslint-disable-line

	const rawData = window.atob(base64);
	const outputArray = new Uint8Array(rawData.length);

	for (let i = 0; i < rawData.length; ++i) {
		outputArray[i] = rawData.charCodeAt(i);
	}
	return outputArray;
}
