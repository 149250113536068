const moment = require('moment');

const initialState = {
	loading: false,
	reportDateRange: [moment().startOf('month').format(), moment().endOf('month').format()],
	reports: [],
	total_time: 0,
	user: null,
};

export default function ReportReducer(state = initialState, action) {
	switch (action.type) {
		case 'SET_LOADING':
			return {
				...state,
				loading: action.payload,
			};
		case 'SET_RANGE':
			return {
				...state,
				reportDateRange: action.payload,
			};
		case 'INIT_DATA':
			return {
				...state,
				loading: false,
				reports: action.payload,
				total_time: action.total_time,
			};
		case 'UPDATE_REPORT_CHILD':
			return {
				...state,
				reports: action.payload.reports,
			};
		case 'UPDATE_REPORTS':
			return {
				...state,
				reports: action.payload.reports,
				total_time: action.payload.total_time,
			};
		case 'SET_USER':
			return {
				...state,
				user: action.payload,
			};
		default:
			return state;
	}
}
