const initialState = {
	tags: [],
	loading: false,
	modalLoading: false,
	tag: {
		id: '',
		name: '',
	},
};
const tagReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'SET_TAG_LOADING':
			return {
				...state,
				loading: action.payload,
			};
		case 'SET_TAG_MODAL_LOADING':
			return {
				...state,
				modalLoading: action.payload,
			};
		case 'INIT_TAG_DATA':
			return {
				...state,
				tags: action.tags,
				loading: false,
			};
		case 'SET_TAGS':
			return {
				...state,
				tags: action.tags,
			};
		case 'NEW_TAG':
			return {
				...state,
				tag: { id: '', name: '' },
				tags: [action.tag, ...state.tags],
			};
		case 'SET_TAG':
			return {
				...state,
				tag: action.payload,
			};
		case 'SET_TAG_NAME':
			return {
				...state,
				tag: { ...state.tag, name: action.payload },
			};
		case 'RESET_TAG':
			return {
				...state,
				tag: { id: '', name: '' },
			};
		default:
			return state;
	}
};

export default tagReducer;
