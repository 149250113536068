const Statuses = [
	{
		id: '1',
		title: 'Open',
		colorCode: '#b8c2cc',
		variant: 'warning',
	},
	{
		id: '2',
		title: 'In Progress',
		colorCode: '#ff9f43',
		variant: 'success',
	},
	{
		id: '3',
		title: 'Done',
		colorCode: '#699bf7',
		variant: 'primary',
	},
	{
		id: '4',
		title: 'Review',
		colorCode: '#f25278',
		variant: 'secondary',
	},
	{
		id: '5',
		title: 'Completed',
		colorCode: '#28c76f',
		variant: 'danger',
	},
];

const STATUSES_ENUM = {
	OPEN: '1',
	INPROGRESS: '2',
	DONE: '3',
	REVIEW: '4',
	COMPLETED: '5',
};

export { Statuses, STATUSES_ENUM };
