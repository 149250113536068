import moment from 'moment';

const initialState = {
	selectedDay: moment().format('YYYY-MM-DD'),
	isLoading: true,
	timers: [],
	weekTotalTime: 0,
	runningID: 0,
	intervalID: null,
	selectedUser: null,
	editedEntry: {},
	isOpenModal: false,
};
const timerReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'SET_OPEN_MODAL':
			return {
				...state,
				isOpenModal: true,
			};
		case 'SET_CLOSE_MODAL':
			return {
				...state,
				isOpenModal: false,
			};
		case 'SET_SELECTED_DAY':
			return {
				...state,
				selectedDay: action.date,
			};
		case 'SET_SELECTED_USER':
			return {
				...state,
				selectedUser: action.user,
			};
		case 'SET_EDITED_ENTRY':
			return {
				...state,
				editedEntry: action.entry,
			};
		case 'RESET_EDITED_ENTRY':
			return {
				...state,
				editedEntry: {},
			};
		case 'UPDATE_EDITED_ENTRY':
			return {
				...state,
				editedEntry: { ...state.editedEntry, ...action.entry },
			};
		case 'FETCHING':
			return {
				...state,
				isLoading: true,
			};
		case 'WEEKLY_TIMERS':
			return {
				...state,
				isLoading: false,
				timers: action.timers,
				weekTotalTime: action.weekTotalTime,
			};
		case 'SET_INTERVAL':
			return {
				...state,
				runningID: action.runningID,
				intervalID: action.intervalID,
			};
		case 'START_INTERVAL':
			return {
				...state,
				timers: action.timer,
				weekTotalTime: state.weekTotalTime + 1,
			};
		case 'STOP_INTERVAL':
			return {
				...state,
				runningID: 0,
				intervalID: null,
			};
		case 'START_TIMER':
			return {
				...state,
				timers: action.payload,
			};
		case 'NEW_ENTRY':
			return {
				...state,
				timers: action.timers,
			};
		case 'REMOVE_ENTRY':
			return {
				...state,
				timers: action.payload,
			};
		case 'UPDATE_ENTRY':
			return {
				...state,
				timers: action.timers,
			};
		default:
			return state;
	}
};

export default timerReducer;
