const initialAggrement = {
	aggrement: {
		name: '',
		startDate: '',
		endDate: '',
		hypeResponsable: [],
		brandResponsable: '',
		lawyer: '',
		comment: '',
		client: null,
		aggrement: null,
		status: '',
	},
	filters: {
		client: null,
		hypeResponsable: [],
		status: null,
		startDate: '',
		endDate: '',
		duration: null,
	},
};

const aggrementReducer = (state = initialAggrement, action) => {
	switch (action.type) {
		case 'SET_AGGREMENT':
			return {
				...state,
				aggrement: {
					...state.aggrement,
					...action.payload,
				},
			};
		case 'AGGREMENT_SET_FILTERS':
			return {
				...state,
				filters: {
					...state.filters,
					...action.payload,
				},
			};
		case 'RESET_AGGREMENT':
			return {
				...state,
				aggrement: initialAggrement,
			};
		default:
			return state;
	}
};

export default aggrementReducer;
