import { Statuses } from '../../../constants/Statuses';

export const initialFiltersState = {
	orderBy: ['dueDate', 'asc'],
	assignedTo: [],
	statuses: [Statuses[0], Statuses[1]],
	projects: [],
	assignedBys: [],
	tags: [],
	createdAt: '',
	priority: '',
	dueDate: {
		start: '',
		finish: '',
	},
};

const filterReducer = (state = initialFiltersState, action) => {
	switch (action.type) {
		case 'CHANGE_FILTER':
			return {
				...state,
				...action.payload,
			};
		case 'CHANGE_ORDER':
			return {
				...state,
				orderBy: action.payload,
			};
		case 'CHANGE_ASSIGNEES':
			return {
				...state,
				assignedTo: [...action.payload],
			};
		case 'INIT_TRELLO_FILTERS':
			if (action.payload) {
				return {
					...action.payload,
				};
			} else {
				return state;
			}
		default:
			return state;
	}
};

export default filterReducer;
