const initialState = {
	departments: [],
	users: [],
	loading: false,
	modalLoading: false,
	department: {
		id: '',
		name: '',
		members: [],
	},
};
const departmentReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'SET_LOADING':
			return {
				...state,
				loading: action.payload,
			};
		case 'SET_MODAL_LOADING':
			return {
				...state,
				modalLoading: action.payload,
			};
		case 'INIT_DATA':
			return {
				...state,
				users: action.users,
				departments: action.departments,
				loading: false,
			};
		case 'SET_DEPARTMENTS':
			return {
				...state,
				departments: action.departments,
			};
		case 'NEW_DEPARTMENT':
			return {
				...state,
				department: { name: '', id: '', members: [] },
				departments: [action.department, ...state.departments],
			};
		case 'SET_DEPARTMENT':
			return {
				...state,
				department: action.payload,
			};
		case 'SET_DEPARTMENT_NAME':
			return {
				...state,
				department: { ...state.department, name: action.payload },
			};
		case 'RESET_DEPARTMENT':
			return {
				...state,
				department: { name: '', id: '', members: [] },
			};
		default:
			return state;
	}
};

export default departmentReducer;
