import React, { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Layout } from './utility/context/Layout';
import * as serviceWorker from './serviceWorker';
import { store } from './redux/storeConfig/store';
import Spinner from './components/@vuexy/spinner/Fallback-spinner';
import jwt from 'jsonwebtoken';
import './index.scss';
import './@fake-db';
import { subscribeUser } from './subscription';
import { Roles } from './constants/Roles';

const LazyApp = lazy(() => import('./App'));

// authentication
const token = localStorage.getItem('user');
if (token) {
	try {
		const user = jwt.decode(token);
		if (Date.now() < user.exp * 1000) {
			const userRole = Roles[user.roles];

			user.is_admin = userRole;
			store.dispatch({ type: 'LOGIN', user, userRole });
		} else {
			localStorage.clear();
		}
	} catch (e) {
		localStorage.clear();
	}
}

ReactDOM.render(
	<Provider store={store}>
		<Suspense fallback={<Spinner />}>
			<Layout>
				<LazyApp />
			</Layout>
		</Suspense>
	</Provider>,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
subscribeUser();
