import { defaultBoard } from '../../../constants/defaultBoard';

const initialState = {
	list: [defaultBoard],
	selectedBoard: +localStorage.getItem('SELECTED_BOARD') || defaultBoard.id,
};

export default function boardReducer(state = initialState, action) {
	switch (action.type) {
		case 'SET_BOARD_LIST':
			if (!!action.payload.length) {
				return {
					...state,
					list: action.payload,
				};
			} else {
				return {
					...state,
					list: [defaultBoard],
				};
			}
		case 'SET_SELECTED_BOARD':
			localStorage.setItem('SELECTED_BOARD', action.payload);
			return {
				...state,
				selectedBoard: action.payload,
			};
		default:
			return state;
	}
}
