const initialState = {
	clients: [],
	users: [],
	tasks: [],
};
const projectReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'INIT_DATA':
			return {
				...state,
				clients: action.clients,
				tasks: action.tasks,
				users: action.users,
			};
		default:
			return state;
	}
};

export default projectReducer;
