import React from 'react';
import * as Icon from 'react-feather';

const navigationConfig = [
	{
		type: 'groupHeader',
		groupTitle: 'MENU',
		permissions: ['admin', 'editor', 'lead', 'customer'],
	},
	// {
	// 	id: 'customer-tasks',
	// 	title: 'Tasks',
	// 	type: 'item',
	// 	icon: <Icon.Trello size={20} />,
	// 	permissions: ['customer'],
	// 	navLink: '/customer-tasks',
	// },
	{
		id: 'my-tasks',
		title: 'My Tasks',
		type: 'item',
		icon: <Icon.Trello size={20} />,
		permissions: ['admin', 'editor', 'lead', 'customer'],
		navLink: '/my-tasks',
	},
	{
		id: 'routines',
		title: 'Routines',
		type: 'item',
		icon: <Icon.List size={20} />,
		permissions: ['admin', 'editor', 'lead'],
		navLink: '/routines',
	},
	{
		id: 'time',
		title: 'Time',
		type: 'item',
		icon: <Icon.Clock size={20} />,
		permissions: ['admin', 'editor', 'lead'],
		navLink: '/time',
	},
	// {
	// 	id: 'reports',
	// 	title: 'Reports',
	// 	type: 'item',
	// 	icon: <Icon.PieChart size={20} />,
	// 	permissions: ['admin', 'editor', 'lead'],
	// 	navLink: '/reports',
	// },
	{
		id: 'expenses',
		title: 'Expenses',
		type: 'item',
		icon: <Icon.CreditCard size={20} />,
		permissions: ['admin', 'editor', 'lead'],
		navLink: '/expenses',
	},
	{
		id: 'people',
		title: 'People',
		type: 'item',
		icon: <Icon.Users size={20} />,
		permissions: ['admin', 'editor', 'lead'],
		navLink: '/people',
	},
	{
		id: 'hyperbot',
		title: 'Hyperbot',
		type: 'item',
		icon: <Icon.Aperture size={20} />,
		permissions: ['admin'],
		navLink: '/hyperbot',
	},
	{
		type: 'groupHeader',
		groupTitle: 'MANAGE',
		permissions: ['editor', 'admin', 'lead'],
	},
	{
		id: 'settings',
		title: 'Settings',
		type: 'item',
		icon: <Icon.Settings size={20} />,
		permissions: ['lead', 'admin'],
		navLink: '/settings',
	},
	{
		id: 'invoices',
		title: 'Invoices',
		type: 'item',
		icon: <Icon.FileText size={20} />,
		permissions: ['admin', 'lead'],
		navLink: '/invoices',
	},
	{
		id: 'agreements',
		title: 'Agreements',
		type: 'item',
		icon: <Icon.Book size={20} />,
		permissions: ['admin', 'lead'],
		navLink: '/sozlesmeler',
	},
	{
		id: 'workload',
		title: 'Workload',
		type: 'item',
		icon: <Icon.BarChart2 size={20} />,
		permissions: ['admin', 'editor', 'lead'],
		navLink: '/workload',
	},
	{
		id: 'csv',
		title: 'CSV',
		type: 'item',
		icon: <Icon.PieChart size={20} />,
		permissions: ['admin'],
		navLink: '/csv',
	},
	{
		id: 'adminReport',
		title: 'Reports',
		type: 'external-link',
		icon: <Icon.PieChart size={20} />,
		permissions: ['editor', 'admin', 'lead'],
		navLink: 'https://hyperactive.pro/admin/reports',
	},
	{
		id: 'budgetTool',
		title: 'Budget Tool',
		type: 'external-link',
		icon: <Icon.CreditCard size={20} />,
		permissions: ['editor', 'admin', 'lead'],
		navLink: 'https://ads-budget.hyperactive.pro',
	},
];

export default navigationConfig;
