export const login = (state = { user: null, userRole: '' }, action) => {
	switch (action.type) {
		case 'LOGIN': {
			return {
				...state,
				user: action.user,
				userRole: action.userRole,
			};
		}
		case 'LOGIN_WITH_EMAIL': {
			return { ...state, values: action.payload };
		}
		case 'LOGIN_WITH_FB': {
			return { ...state, values: action.payload };
		}
		case 'LOGIN_WITH_TWITTER': {
			return { ...state, values: action.payload };
		}
		case 'LOGIN_WITH_GOOGLE': {
			return { ...state, values: action.payload };
		}
		case 'LOGIN_WITH_GITHUB': {
			return { ...state, values: action.payload };
		}
		case 'LOGIN_WITH_JWT': {
			return { ...state, values: action.payload };
		}
		case 'USER_LOGOUT': {
			return { ...state, user: null, userRole: '' };
		}
		case 'CHANGE_ROLE': {
			return { ...state, userRole: action.userRole };
		}
		default: {
			return state;
		}
	}
};
