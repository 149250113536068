const initialState = {
	expenses: [],
	isLoading: false,
	selectedUser: null,
	modalLoading: false,
	dateRange: null,
	projects: [],
	categories: [],
	expense: {
		date: new Date(),
		project: '', //{ value: "", label: "" },
		category: '', //{ value: "", label: "" },
		notes: '',
		is_billable: false,
		amount: 0,
	},
	receipt: null,
};

export default function expenseReducer(state = initialState, action) {
	switch (action.type) {
		case 'SET_LOADING':
			return {
				...state,
				isLoading: action.payload,
			};
		case 'SET_MODAL_LOADING':
			return {
				...state,
				modalLoading: action.payload,
			};
		case 'FETCH_EXPENSE':
			return {
				...state,
				expenses: action.expenses,
				isLoading: false,
			};
		case 'UPDATE_EXPENSES':
			return {
				...state,
				expenses: action.expenses,
			};
		case 'NEW_EXPENSE':
			return {
				...state,
				expenses: [action.expense, ...state.expenses],
			};
		case 'SET_CATEGORIES':
			return {
				...state,
				categories: action.categories,
			};
		case 'SET_PROJECTS':
			return {
				...state,
				projects: action.projects,
			};
		case 'SET_SELECTED_USER':
			return {
				...state,
				selectedUser: action.user,
			};
		case 'SET_EXPENSE':
			return {
				...state,
				expense: action.expense,
			};
		case 'RESET_EXPENSE':
			return {
				...state,
				expense: {
					date: new Date(),
					project: '', //{ value: "", label: "" },
					category: '', //{ value: "", label: "" },
					notes: '',
					is_billable: false,
					amount: 0,
				},
			};
		case 'SET_RECEIPT':
			return {
				...state,
				receipt: action.receipt,
			};
		case 'SET_RANGE':
			return {
				...state,
				dateRange: action.payload,
			};
		default:
			return state;
	}
}
